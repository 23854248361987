import React from "react";
import { Link } from "gatsby";

import { PageMeta } from "../components/pageMeta";

const SubmissionConfirmation = () => {
    return (
        <div className="h-screen w-full px-gutter bg-teal-01 flex items-center">
            <PageMeta metaTitle="Thank You" />
            <div className="text-warm-white">
                <h2 className="text-5xl mb-2">Thank You</h2>
                <p className="text-xl">
                    Thanks for contacting us, we will be in touch as soon as we
                    can.
                </p>

                <div className=" flex uppercase blockH11 text-center font-bold tracking-widest mt-10 ">
                    <Link
                        className="button  px-8 py-5 col-span-1 bg-teal text-warm-white hover:text-warm-white hover:bg-raspberry transition-all duration-300 ease-in-out "
                        to="/work"
                    >
                        View our work
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default SubmissionConfirmation;
